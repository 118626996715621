import StoryblokClient, { AlternateObject } from 'storyblok-js-client';
import uuid4 from 'uuid';
import { StoryDataFromGraphQLQuery } from '../../templates/types';
import { StoryblokService } from '../storyblok';
import { LanguageService } from '../language';
import { StringService } from '../string';
import { LocalizedTag } from './types';

export const BuildTopicService = {
  client: new StoryblokClient({
    accessToken: StoryblokService.getConfig().options.accessToken as string,
  }),

  fetchTagsFromStoryblok: async (datasourceName: string, dimension: string = '') => {
    try {
      const response = await StoryblokService.getDatasources(
        BuildTopicService.client,
        [datasourceName],
        dimension ? { dimension, cv: Date.now() } : {},
      );
      return response[0]?.data?.datasource_entries || [];
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error fetching tags: ${error.message}`);
      return [];
    }
  },

  fetchTagsAndDescriptions: async (
    prefix: string = '',
    /* eslint-disable @typescript-eslint/no-explicit-any */
  ): Promise<{ tagsDatasource: LocalizedTag[]; descriptionsDatasource: any[] }> => {
    const tagsDatasource = await BuildTopicService
      .fetchTagsFromStoryblok('tags', prefix);
    const descriptionsDatasource = await BuildTopicService
      .fetchTagsFromStoryblok('tags-descriptions', prefix);
    return { tagsDatasource, descriptionsDatasource };
  },

  getDatasourceTags: async (
    defaultLang: string,
    locales: string[],
    allStories: StoryDataFromGraphQLQuery[],
  ) => {
    const tagsByLocale: Record<string, Set<LocalizedTag>> = {};
    const tagValueToSetMap: Record<string, Set<LocalizedTag>> = {};

    const {
      tagsDatasource: defaultTags,
      descriptionsDatasource: defaultDescriptions,
    } = await BuildTopicService
      .fetchTagsAndDescriptions(defaultLang);

    defaultTags.forEach((tag: LocalizedTag) => {
      if (!tagValueToSetMap[tag.value]) {
        tagValueToSetMap[tag.value] = new Set();
      }
      const description = defaultDescriptions.find((d) => d.name === tag.name);
      const updatedTag = {
        ...tag,
        description: description
          ? (description.dimension_value || description.value)
          : tag.value,
        locale: defaultLang,
      };
      tagValueToSetMap[tag.value].add(updatedTag);
    });

    const fetchPromises = locales.map(async (locale) => {
      const prefix = locale === defaultLang ? '' : locale;
      const {
        tagsDatasource,
        descriptionsDatasource,
      } = await BuildTopicService.fetchTagsAndDescriptions(prefix);

      if (!tagsByLocale[locale]) {
        tagsByLocale[locale] = new Set();
      }

      tagsDatasource.forEach((tag: LocalizedTag) => {
        const description = descriptionsDatasource.find((d) => d.name === tag.name);
        const updatedTag = {
          ...tag,
          description: description
            ? (description.dimension_value || description.value)
            : tag.value,
        };
        tagsByLocale[locale].add(updatedTag);
      });
    });
    await Promise.all(fetchPromises);

    allStories.forEach((story: StoryDataFromGraphQLQuery) => {
      const locale = LanguageService.getLocaleFromSlug(story.full_slug);

      story?.content?.tag?.forEach(async (tag: string) => {
        tagsByLocale[locale].forEach((localizedTag: LocalizedTag) => {
          const alreadyExists = Array.from(tagValueToSetMap[tag] ?? [])
            .some((existingTag: LocalizedTag) => existingTag.locale === locale);

          if (localizedTag.value === tag && !alreadyExists) {
            tagValueToSetMap[tag]?.add({ ...localizedTag, locale });
          }
        });
      });
    });

    return tagValueToSetMap;
  },

  generateAlternateUrls: (
    tags: Record<string, Set<LocalizedTag>>,
    tag: LocalizedTag,
    tagKey: string,
    defaultLang: string) => {
    const alternateUrls: AlternateObject[] = [];
    tags[tagKey].forEach((alternateTag) => {
      if (tag.locale !== alternateTag.locale) {
        const slug = alternateTag.dimension_value || alternateTag.value;
        const urlPrefix = alternateTag.locale === defaultLang ? '' : `/${alternateTag.locale}`;
        alternateUrls.push({
          full_slug: `${urlPrefix}/topic/${StringService.urlify(slug)}`,
          id: uuid4(),
          slug: StringService.urlify(slug),
          published: true,
          name: slug,
          is_folder: false,
          parent_id: 0,
        });
      }
    });
    return alternateUrls;
  },

  getDatasourceTopicPageTags: async () => {
    const sbTagData = await StoryblokService.getDatasources(BuildTopicService.client, ['topic-page-tags']);
    return sbTagData.flatMap(({ data }) => data.datasource_entries);
  },
  getTopicPageContent: (
    tag: string,
    description: string,
    translatedTag: string,
    translations,
    exploreMoreTags) => ({
    component: 'page',
    meta_tags: {
      title: `Roche - ${translatedTag}`,
      description: description || '',
    },
    body: [
      {
        component: 'roche-grid-12',
        slotted: [
          {
            component: 'roche-staging-hero',
            variant: 'hero--variant-b',
            headline: translatedTag,
          },
        ],
      },
      {
        component: 'roche-grid-12',
        full_width: false,
        slotted: [
          {
            component: 'roche-teaser-wall',
            disable_filters: true,
            disable_load_more: false,
            teasers: [
              {
                tags: [tag],
                layout: 'story-teaser--variant-a',
                sorting: 'first_published_at:desc',
                component: 'roche-story-teaser-auto',
                group_tags: [],
                teaser_number: 12,
              },
            ],
          },
        ],
      },
      {
        component: 'roche-grid-12',
        full_width: false,
        color: 'neutral-gradient-neutral-100-neutral-400',
        slotted: [
          {
            component: 'roche-headline',
            level: 2,
            headline: translations['explore more'],
          },
          ...exploreMoreTags?.map((tag) => ({
            component: 'roche-tag',
            tag: tag.value,
          })),
        ],
      },
    ],
  }),
};
